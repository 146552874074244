import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import getHeroImgVariables from "../helpers/getHeroImgVariables";

import SecondaryLanding from "../components/layouts/secondary-landing";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import BestBanksDefault from "../components/best-banks/best-banks-default";
import HeroChevron from "../components/hero/hero-chevron";
import FaqAccordion from "../components/faq/faq-accordion";

const KrakenTickets = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/tickets/hero-kraken-contest-faq-061324-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/tickets/hero-kraken-contest-faq-061324-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/tickets/hero-kraken-contest-faq-061324-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/tickets/hero-kraken-contest-faq-061324-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/tickets/hero-kraken-contest-faq-061324-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/tickets/hero-kraken-contest-faq-061324-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/tickets/hero-kraken-contest-faq-061324-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "kraken-tickets-hero",
    ...getHeroImgVariables(imgData),
    altText: "Seattle Kraken players in action.",
    chevronBgClass: "bg-kraken-primary-blue",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Like You, We Are Huge Fans of the Kraken! Let's Score You Seats for the Action."
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us/wafd-sports/seattle-kraken",
      title: "Seattle Kraken"
    },
    {
      id: 2,
      active: true,
      title: "WaFd Bank Half-Season Ticket Membership Offer"
    }
  ];

  const faqAccordionData = {
    id: "kraken-tickets-faq-accordion",
    faqCategoryNames: ["Kraken Ticket Membership Offer"],
    showContactBar: false
  };

  const title = "Seattle Kraken / WaFd Bank Half-Season Ticket Offer",
    description =
      "Receive a $200 team store gift card and an invite to an exclusive Kraken player meet and greet at the end of the season when you make payments on your season tickets using your WaFd Kraken debit card.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/tickets"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-kraken-contest-faq-061324.jpg"
      }
    ]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1 className="mb-0">Seattle Kraken / WaFd Bank Half-Season Ticket Membership Offer*</h1>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default KrakenTickets;
